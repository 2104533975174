import PropTypes from "prop-types";
import * as React from "react";
// import GlobalStyles from "../styles/GlobalStyles";
// import TypographyStyles from "/styles/TypographyStyles";
import Footer from "./Footer";
// import "./layout.css";
import Navbar from "./Navbar";

const Layout: React.FC = ({ children }) => {
	return (
		<React.Fragment>
			<Navbar />
			<div className="layout">{children}</div>
			<Footer />
		</React.Fragment>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
