export default [
	{
		path: "/",
		text: "Home",
	},
	// {
	// 	path: "/about",
	// 	text: "About",
	// },
	// {
	// 	path: "/",
	// 	text: "FAQs",
	// },
	{
		path: "/contact",
		text: "Contact",
	},
];
