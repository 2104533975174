import { Link } from "gatsby";
import React, { useImperativeHandle, useRef } from "react";
import styled from "styled-components";
import links from "../../constants/links";

class Menu extends React.Component {
	constructor(props: any) {
		super(props);
		this.state = {
			open: false,
		};
	}

	render() {
		return (
			<MenuStyles
				role="button"
				tabIndex="0"
				className={(this.state as any).open ? "menu open" : "menu"}
				onClick={() => this.close()}
				onKeyDown={() => this.close()}
			>
				{links.map((item, index) => {
					return (
						<Link key={index} to={item.path}>
							<h1>{item.text}</h1>
						</Link>
					);
				})}
			</MenuStyles>
		);
	}

	close() {
		this.setState({ open: false });
	}

	open() {
		this.setState({ open: true });
	}
}
export const MenuStyles = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: #ffffff;
	z-index: 1031;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	visibility: hidden;
	opacity: 0;

	transition: all 0.35s;

	cursor: pointer;

	&.open {
		visibility: visible;
		opacity: 1;
	}

	a {
		// text-shadow: 0px 1px var(--secondary-color);
		color: var(--secondary-color);
		pointer-events: all;
		text-decoration: none;
		transition: transform 0.35s;
		&:hover,
		&:active {
			text-decoration: none;
			transform: scale(1.5);
			color: var(--primary-color) !important;
            // text-shadow: 0px 1px var(--primary-color) !important;
		}
		&:visited {
			color: var(--secondary-color);
		}
	}
`;
export default React.forwardRef((props, ref) => {
	const menuRef = useRef();

	useImperativeHandle(ref, () => ({
		open() {
			menuRef.current.open();
		},
	}));

	return <Menu ref={menuRef} {...props} />;
});
