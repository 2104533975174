import React from "react";
import { FaTwitter, FaInstagram } from "react-icons/fa";

export const mainMenuItems = [
	{
		path: "/",
		title: "home",
	},
	{
		path: "/policies/terms",
		title: "Terms and Conditions",
	},
	{
		path: "/policies/privacy",
		title: "Privacy Policy",
	},
];

export const socialMenuItems = [
	{
		icon: <FaInstagram />,
		url: "https://www.instagram.com/officialkitchenshelf/",
		name: "Instagram",
	},
	{
		icon: <FaTwitter />,
		url: "https://twitter.com/realKshelf",
		name: "Twitter",
	},
];

// export const footerMenuItems = [
//   {
//     path: "/privacy",
//     title: "privacy",
//   },
//   {
//     path: "/cookies",
//     title: "cookies",
//   },
// ]
