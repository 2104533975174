import { graphql, Link, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { mainMenuItems, socialMenuItems } from "../constants/menu-items";

type FooterProps = {
	Logo?: string;
};

const Footer = ({ Logo }: FooterProps) => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						author
						authorSite
					}
				}
			}
		`
	);

	const footerData = data.site.siteMetadata;

	return (
		<FooterStyles>
			<div className="grid">
				{Logo ? (
					<div className="brand-cont">
						{Logo && (
							<div className="logo">
								<Link to="/">
									<img src={Logo} alt={`${footerData.title} logo`} />
								</Link>
							</div>
						)}

						{socialMenuItems && (
							<ul className="socials">
								{socialMenuItems.map((item, index) => {
									return (
										<li key={index}>
											<a
												href={item.url}
												target="_blank"
												rel="noopener noreferrer"
											>
												{item.icon}
												<span className="sr-only">{item.name}</span>
											</a>
										</li>
									);
								})}
							</ul>
						)}

						{/* <div>
              &copy; {new Date().getFullYear()}
              <span> All rights reserved</span>
            </div>
            <address>
              ...
              <br />
              ...
              <br />
              ...
            </address>
            <a className="telephone" href="tel:+004407076009211">
              Coming Soon
            </a> */}
					</div>
				) : null}

				{mainMenuItems || socialMenuItems ? (
					<div className="menus-cont">
						{/* If main menu items are being imported, render this */}
						{mainMenuItems && (
							<ul className="footer-menu">
								{/* First we want to filter out the Home menu item, then display the rest of them */}
								{mainMenuItems
									.filter((item) => {
										return item.title !== "home";
									})
									.map((item, index) => (
										<li key={`menuItem${index}`}>
											<Link to={item.path}>{item.title}</Link>
										</li>
									))}
							</ul>
						)}
					</div>
				) : null}
			</div>
			<div className="copy-cont">
				<ul className="copy">
					<li>&copy; {new Date().getFullYear()}</li>

					{/* if there is an author stated in the config, render this */}
					{footerData.author && (
						<li>
							<a
								href={footerData.authorSite}
								target="_blank"
								rel="noopener noreferrer"
							>
								{footerData.author}
							</a>
						</li>
					)}
				</ul>
			</div>
		</FooterStyles>
	);
};

Footer.propTypes = {
	Logo: PropTypes.string,
};

const FooterStyles = styled.footer`
	padding: calc(var(--spacing) * 2);
	background-color: #fff;
	font-family: var(--sansSerif);
	font-weight: 300;
	font-style: normal;
	color: #12121d;

	.grid {
		width: 100%;
		display: grid;
		max-width: 1400px;
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
		margin-left: auto;
		margin-right: auto;

		@media (min-width: 768px) {
		}
	}

	.telephone,
	address,
	li {
		font-size: var(--footerMenuItem);
	}

	address {
		font-style: normal;
		margin-bottom: var(--spacing);
	}

	li {
		text-transform: capitalize;
		list-style: none;
		padding: 10px;
	}

	a {
		text-decoration: none;
		color: #12121d;
		transition: var(--transMed);

		&:hover {
			color: var(--primary-color);
		}
	}

	@media (min-width: 768px) {
		padding-top: calc(var(--spacing) * 4);
		padding-bottom: calc(var(--spacing) * 2);
	}

	.brand-cont {
		margin-bottom: calc(var(--spacing));
	}

	.brand-cont {
		.logo {
			margin: 0 0 calc(var(--spacing)) 0;
			img {
				max-width: 125px;
				width: 100%;
			}
		}
	}

	.menus-cont {
	}

	.footer-menu {
		padding: 0;
		li {
			padding-left: 0;
		}
	}

	.socials {
		display: flex;
		margin: 0;
		padding: 0;
		li {
			margin-top: 0;
			padding: 0;
			margin-right: var(--spacing);

			&:last-child {
				margin-right: 0;
			}
		}

		a {
			font-size: 24px;
		}
	}
	.copy-cont {
		width: 100%;
		border-top: 1px solid rgba(255, 255, 255, 0.1);
		margin-top: calc(var(--spacing) * 2);
		padding-top: calc(var(--spacing) * 2);

		@media (min-width: 768px) {
			border-top: none;
		}

		.copy {
			display: flex;
			padding: 0;
			margin-top: 0;

			margin-bottom: 0;

			li {
				margin-right: var(--spacing);
			}

			@media (min-width: 768px) {
				justify-content: center;

				li {
					margin: 0 calc(var(--spacing) / 2);
					font-size: 0.75rem;
				}
			}
		}
	}
`;

export default Footer;
