import React from "react";
import styled from "styled-components";
import Button from "./Button/button";
import Menu from "./menu/menu";
import { GiHamburgerMenu } from "react-icons/gi";

// const CSSVariables = styled.div`
// 	--primary-c: #3f7cac;
// 	--secondary: #00cc99;
// `;

const Navbar = () => {
	let childMenu: any;

	const toggleMenu = () => {
		childMenu.open();
	};

	return (
		<NavStyles>
			<div className="masthead flex-container">
				<MenuButtonStyles onClick={toggleMenu}>
					<GiHamburgerMenu />
				</MenuButtonStyles>
				<JustifyRight>
					{/* <CSSVariables> */}
					<Button
						anchor="true"
						href="https://app.kitchenshelf.co.uk/"
						label="Sign In"
						cta="Sign In"
					/>
					{/* </CSSVariables> */}
				</JustifyRight>

				<Menu ref={(el) => (childMenu = el)} />
			</div>
		</NavStyles>
	);
};
export const MenuButtonStyles = styled.button`
	display: grid;
	align-content: center;
	border: 0;
	outline: 0;
	background: transparent;
	cursor: pointer;
	svg {
		font-size: 35px;
	}
`;

const JustifyRight = styled.div`
	padding: 5px 0;
	justify-self: end;
	align-self: start;
	a {
		max-width: initial;
		height: 40px;
		line-height: 40px;
	}
`;

export const NavStyles = styled.nav`
	position: absolute;
	z-index: 10;
	top: 0;
	width: 100%;
	padding: 1rem;

	.masthead {
		display: grid;
		grid-template-columns: auto 1fr;

		img {
			height: 100%;
			@media (min-width: 768px) {
				width: 180px;
			}
		}
	}
`;

export default Navbar;
