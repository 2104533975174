/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from "react";
import Layout from "./src/components/layout";
import GlobalStyles from "./src/styles/GlobalStyles";
import TypographyStyles from "./src/styles/TypographyStyles";
export function wrapPageElement({ element, props }) {
	return (
		<React.Fragment>
			<TypographyStyles />
			<GlobalStyles />
			<Layout {...props}>{element}</Layout>
		</React.Fragment>
	);
}
